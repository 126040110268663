<template>
  <!-- 录入核酸结果 -->
  <el-dialog
    v-if="isArrange"
    title="录入核酸结果"
    :visible.sync="isArrange"
    width="600px"
    :before-close="closeArrange">
    <el-form
      style="width:100%"
      :rules="rules"
      ref="redformhsRef"
      :model="redformhs"
      label-width="120px">

      <el-form-item label="采样时间：" prop="collectTime">
        <el-date-picker
          style="width:90%"
          type="datetime"
          v-model="redformhs.collectTime"
          format="yyyy-MM-dd HH:mm:ss"
          value-format="yyyy-MM-dd HH:mm:ss"
          placeholder="选择时间"
        ></el-date-picker>
      </el-form-item>

      <el-form-item label="核酸结果：" prop="labResult">
        <el-radio-group style="width:90%;" v-model="redformhs.labResult">
          <el-radio label="阴性">阴性</el-radio>
          <el-radio label="阳性">阳性</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="报告时间：" prop="reportTime">
        <el-date-picker
          style="width:90%"
          type="datetime"
          v-model="redformhs.reportTime"
          format="yyyy-MM-dd HH:mm:ss"
          value-format="yyyy-MM-dd HH:mm:ss"
          placeholder="选择时间"
        ></el-date-picker>
      </el-form-item>

      <el-form-item label="检测机构：" prop="labOrg">
        <el-input style="width:90%" v-model="redformhs.labOrg" placeholder="请输入" />
      </el-form-item>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="closeArrange">取消</el-button>
      <el-button size="small" type="primary" @click="submitFrom">保存</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { collectDetect } from '../../../api/QuarantineRegistration/quarantineRegistrationpop';

export default {
  name: "InputNucleicAcid",
  components: {},
  data() {
    return {
      isArrange: false, //安排隔离弹窗
      lridata: null,
      orgInfo: JSON.parse(localStorage.getItem("orgInfo")),
      redformhs: {
        collectTime: "", //检测时间
        labResult: "",
        labOrg: "",
        reportTime:''
      },
      rules: {
        collectTime: [{ required: true, message: "请选择采样时间", trigger: "blur" }],
        labResult: [{ required: true, message: "请选择核酸结果", trigger: "blur" }],
        labOrg: [{ required: true, message: "请输入检测机构", trigger: "blur" }],
        reportTime: [{ required: true, message: "请选择采样时间", trigger: "blur" }],
      },
      regId: ""
    }
  },

  props: {
    // 此组件在多个组件中引用，该属性用于区别用于哪个组件
    inputType: {
      type: String,
      default: ""
    }
  },

  methods: {
    show(data, regId) {
      this.isArrange = true;
      this.lridata = data;
      this.regId = regId;
      this.redformhs = {
        collectTime: "",
        labResult: "",
        labOrg: "",
        reportTime:""
      }
    },

    submitFrom() {
      let params = {
        ...this.redformhs,
        orgId: this.orgInfo.id,
        regId: this.lridata.id || this.regId,
        id: this.lridata.taskId
      };
      this.$refs["redformhsRef"].validate(async valid => {
        if (valid) {
          let { data } = await collectDetect(params);
          if (data.code == 200) {
            this.$message.success("录入核酸成功");
            this.isArrange = false;

            if (this.inputType === "待办任务") {
              this.$parent.queryDataInfo();
            } else if (this.inputType === "居民详情") {
              this.$emit("refreshData")
            }
          } else {
            this.$message.error(data.msg);
          }
        }
      });
    },

    // 关闭隔离弹窗
    closeArrange() {
      this.isArrange = false;
    }
  }
}
</script>

<style scoped lang="scss">

</style>
